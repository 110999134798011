
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import { QRCodeViewer, PhoneNumberInput, BaseButton, ButtonLink, BaseTextInput, BaseIcon } from '@/lib/components';
import { TokenService } from '@/services';
import axios from 'axios';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useUiStore } from '@/stores/ui.store';

dayjs.extend(utc);

@Options({
  components: {
    BaseTextInput,
    BaseButton,
    BaseIcon,
    ButtonLink,
    PhoneNumberInput,
    QRCodeViewer,
    LoginLayout
  }
})
export default class LoginPage extends Vue {
  protected username = '';
  protected password = '';
  protected loading = false;
  protected isVerifing = false;
  protected errorMessage = '';
  protected user_id = '';
  protected mfa_token = '';
  protected mfa_type = '';
  protected otp_code = '';
  protected oob_code = '';
  protected sms_code = '';
  protected step = 1;
  protected contactNumber = '';
  protected isEnrolled = false;
  protected barcode_uri = '';
  protected barcode_size = 250;
  protected recovery_code = '';
  protected input_recovery_code = '';
  protected useRecoveryCode = false;
  sessionStore = useSessionStore();
  patientStore = usePatientStore();
  uiStore = useUiStore();

  get env() {
    return process.env.VUE_APP_ENV;
  }

  mounted() {
    this.$watch('mfa_type', this.updateType);
  }

  updateType() {
    if (this.isEnrolled && this.mfa_type === 'sms') {
      this.fetchCurrentUserSMSChallenge();
    }
  }

  async attemptLogin() {
    const options = {
      username: this.username,
      password: this.password,
      grant_type: 'password'
    };

    try {
      this.loading = true;
      const response = await this.sessionStore.login(options);
      this.errorMessage = '';
      if (response.access_token) {
        this.setCredentials();
        await this.postLoginRedirect();
      } else if (response.error === 'mfa_required' && !this.hasEnrolled(response.mfa_enrollments)) {
        this.step = 2;
        this.mfa_token = response.mfa_token;
        this.user_id = response.user_id;
        this.mfa_type = 'otp';
        this.fetchCurrentUserOTP();
      } else if (response.error === 'mfa_required' && this.hasEnrolled(response.mfa_enrollments)) {
        this.step = 3;
        this.isEnrolled = true;
        this.mfa_token = response.mfa_token;
        this.user_id = response.user_id;
        this.mfa_type = this.checkMfaType(response.mfa_enrollments) === 'sms' ? 'sms' : 'otp';
        this.contactNumber = this.getEnrolledSmsNumber(response.mfa_enrollments).replace(new RegExp('X', 'g'), '*');
      } else {
        await this.$router.push({ name: 'login' });
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.error === 'password_reset_required' &&
        error.response.status === 401
      ) {
        await this.$router.push({
          name: 'weak-password',
          params: { email: this.username, forceReset: true }
        });
      } else if (error.response?.data) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorMessage = error.message;
      }
    } finally {
      this.loading = false;
      this.uiStore.loading = false;
    }
  }

  // This is for OTP first-time associate
  async fetchCurrentUserOTP() {
    const params = {
      user_id: this.user_id,
      mfa_token: this.mfa_token,
      authenticator_types: ['otp']
    };
    try {
      const response = await this.sessionStore.fetchUserOTP(params);
      this.errorMessage = '';
      this.barcode_uri = response.barcode_uri;
      this.recovery_code = response.recovery_codes[0];
    } catch (error) {
      this.errorMessage = error.response?.data?.message || '';
    }
  }

  // This is for SMS first-time associate
  async fetchCurrentUserSMS() {
    const params = {
      mfa_token: this.mfa_token,
      authenticator_types: ['oob'],
      oob_channels: ['sms'],
      phone_number: this.contactNumber,
      user_id: this.user_id
    };
    try {
      const response = await this.sessionStore.fetchUserSms(params);
      this.errorMessage = '';
      this.oob_code = response.oob_code;
      this.recovery_code = response.recovery_codes[0];
    } catch (error) {
      this.errorMessage = error.response?.data?.message || '';
    }
  }

  // This is for SMS-MFA after first associate
  async fetchCurrentUserSMSChallenge() {
    const params = {
      mfa_token: this.mfa_token,
      challenge_type: 'oob'
    };
    try {
      const response = await this.sessionStore.fetchUserSmsChallenge(params);
      this.errorMessage = '';
      this.oob_code = response.oob_code;
    } catch (error) {
      this.errorMessage = error.response?.data?.message || '';
    }
  }

  async associate() {
    this.errorMessage = '';

    if (this.useRecoveryCode) {
      const options = {
        grant_type: 'mfa-recovery-code',
        recovery_code: this.input_recovery_code,
        mfa_token: this.mfa_token
      };
      try {
        this.isVerifing = true;
        const response = await this.sessionStore.login(options);
        if (response.access_token) {
          this.setCredentials();
          this.step = 4;
          this.recovery_code = response.recovery_code;
        } else {
          this.$router.push({ name: 'login' });
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || '';
      } finally {
        this.isVerifing = false;
      }
    } else if (this.mfa_type === 'otp') {
      const options = {
        grant_type: 'mfa-otp',
        otp: this.otp_code,
        mfa_token: this.mfa_token,
        user_id: this.user_id
      };
      try {
        this.isVerifing = true;
        const response = await this.sessionStore.login(options);
        if (response.access_token) {
          this.setCredentials();
          this.step = 4;
        } else {
          this.$router.push({ name: 'login' });
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || '';
      } finally {
        this.isVerifing = false;
      }
    } else if (this.mfa_type === 'sms') {
      const options = {
        grant_type: 'mfa-oob',
        oob_code: this.oob_code,
        binding_code: this.sms_code,
        mfa_token: this.mfa_token,
        user_id: this.user_id
      };
      try {
        this.isVerifing = true;
        const response = await this.sessionStore.login(options);
        if (response.access_token) {
          this.setCredentials();
          this.step = 4;
        } else {
          this.$router.push({ name: 'login' });
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || '';
      } finally {
        this.isVerifing = false;
      }
    }
  }

  hasEnrolled(enrollments: Array<{ status: string; type: string }>) {
    return enrollments.filter((enrollment: { status: string }) => enrollment.status === 'confirmed').length > 0;
  }

  checkMfaType(enrollments: Array<{ status: string; type: string }>) {
    return enrollments.filter((enrollment: { status: string }) => enrollment.status === 'confirmed')[0].type;
  }

  getEnrolledSmsNumber(enrollments: Array<{ status: string; type: string; phone_number: string }>) {
    return enrollments.find((enrollment: { type: string }) => enrollment.type === 'sms')?.phone_number ?? '';
  }

  toResetPassword() {
    this.$router.push({ name: 'reset-password' });
  }

  async postLoginRedirect() {
    try {
      this.uiStore.loading = true;
      await this.sessionStore.fetchCurrentUser();
      await this.sessionStore.fetchAdminCustomer();
      await this.patientStore.fetchExternalPatientReferenceTypes();
    } catch (e) {
      TokenService.removeToken();
      await this.$router.push({ name: 'login' });
    } finally {
      if (this.sessionStore.currentUser?.enforce_password_reset_at) {
        await this.$router.push({
          name: 'weak-password',
          params: {
            email: this.sessionStore.currentUser?.email,
            enforceDate: this.sessionStore.currentUser?.enforce_password_reset_at
          }
        });
      } else if (this.sessionStore.currentUser?.is_admin) {
        await this.$router.push({ name: 'domain-admin' });
      } else if (this.sessionStore.currentOrganisationId) {
        await this.$router.push({
          name: 'patient-list',
          params: { organisationId: this.sessionStore.currentOrganisationId }
        });
      } else {
        await this.$router.push({ name: 'settings' });
      }

      this.uiStore.loading = false;
    }
  }

  setCredentials() {
    TokenService.setToken(this.sessionStore.token);
    TokenService.setRefreshToken(this.sessionStore.refreshToken);
    TokenService.setExpireDate(this.sessionStore.expireDate);
    TokenService.setLoadingToken('false');
  }

  toggleRecoveryCode() {
    this.errorMessage = '';
    this.otp_code = '';
    this.sms_code = '';
    this.input_recovery_code = '';
    this.useRecoveryCode = !this.useRecoveryCode;
  }
}
